import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'creators.snap.com',

  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.CREATORS_SNAP },
      {
        vendor: LoggingVendor.GOOGLE,
        googleAnalyticsId: 'G-4H6GYDJHRL',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'creators-snap-com',
        dsn: 'https://54d7a46fa00049d69bde2cd51d61d859@sentry.sc-prod.net/153',
      },
    ],
  },
  globalNavProps: {
    siteName: 'Snap Creators',
  },
};
